const login ="https://demo.host421.com/nodeapi/login"
const addnewquotation ="https://demo.host421.com/nodeapi/addquotation"
const viewlist = "https://demo.host421.com/nodeapi/quotationlist"
const quotationdetail ="https://demo.host421.com/nodeapi/Quotationdetail/"
const updateUrl ="https://demo.host421.com/nodeapi/updatequotation/"


 const viewservices ="https://demo.host421.com/nodeapi/servicelist"
 const addservices ="https://demo.host421.com/nodeapi/addservices"





// const login ="http://localhost:3005/api/v1/admin/login"
// const addnewquotation ="http://localhost:3005/api/v1/admin/addquotation"
// const viewlist = "http://localhost:3005/api/v1/admin/viewList"
// const quotationdetail ="http://localhost:3005/api/v1/admin/quotationdetail/"
// const updateUrl ="http://localhost:3005/api/v1/admin/updatequotation/"
// const PendingUrl ="http://3.108.40.94:3003/api/v1/patient/SearchPatient"

export {login,updateUrl,quotationdetail,addnewquotation,viewlist,viewservices,addservices
}