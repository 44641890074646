

import react,{ useState,} from "react";

import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logo from "assets/myimages/cb.png";
import { login } from "./MyApi/Apilist";
import { Alert } from "@mui/material";

function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [rememberMe, setRememberMe] = useState(false);

    const handleSetRememberMe = () => setRememberMe(!rememberMe);



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Send login request to the server
            // const response = await axios.post('http://localhost:3005/api/v1/admin/login', {
            const response = await axios.post(login, {
                email,
                password,
            });

            // Handle the response as needed
            console.log('Login successful:', response.data);

            // Reset the form after successful submission
            setEmail('');
            setPassword('');
            
                localStorage.setItem('user', JSON.stringify(response));
                    
            // Redirect to the dashboard page
            navigate('/dashboard');
        } catch (error) {
            // Handle errors (e.g., display an error message)
          alert("Invalid Credential ")
            console.error('Login failed:', error.message);
        }
    };




    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    style={styles.boxStyle1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="info" mt={1}>
                        Sign in
                    </MDTypography>
                    <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>

                        <Grid item xs={1}>

                            <MDTypography component={MuiLink} href="#" variant="body1" color="white" >
                                <img src={logo} alt="Cod Bracket" style={{ width: '250px', height: '50px', marginLeft: -125 }} />
                                {/* <GitHubIcon color="inherit" /> */}
                            </MDTypography>

                        </Grid>
                    </Grid>
                </MDBox>



                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form"  >
                        <MDBox mb={2}>
                            <MDInput type="email" label="Email" fullWidth value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password" label="Password" fullWidth
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                onClick={handleSetRememberMe}
                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                                &nbsp;&nbsp;Remember me
                            </MDTypography>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton type="button" onClick={handleSubmit} variant="gradient" color="info" fullWidth style={styles.logibtn}>
                                sign in
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                Don&apos;t have an account?{" "}
                                <MDTypography
                                    component={Link}
                                    to="/authentication/sign-up"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Sign up
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Login;


const styles = {
    boxStyle1: {
        background: "#fff",
    },
    logibtn: {
        background: "#0fce6e",
    }
}
