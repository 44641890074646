
// Material Dashboard 2 React components
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";


import GitHubIcon from "@mui/icons-material/GitHub";
import PreviewIcon from '@mui/icons-material/Preview';
import HomeWork from "@mui/icons-material/HomeWork";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import Dashboard from 'layouts/dashboard';
import { viewlist } from './MyApi/Apilist';
import Mydashboard from './Mydashboard';
// imports


function Todaylist() {

    const [todayQuotation, setTodayQuotation] = useState([]);
    const navigate = useNavigate();



    useEffect(() => {
        // Fetch data from the server to get total patients and total test lists
        const fetchData = async () => {
            try {
                // const quotationResponse = await axios.get('http://localhost:3005/api/v1/admin/viewList');
                const quotationResponse = await axios.get(viewlist);
                // Todays Quotation
                const today = new Date().toISOString().split('T')[0];
                const todayQuotations = quotationResponse.data.filter(
                    (quotation) => new Date(quotation.createdAt).toISOString().split('T')[0] === today
                );

                // Set total pending quotations
                setTodayQuotation(todayQuotations);
                // console.log(totalQuotation)


            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData();
    }, []);



    const handleLogout = () => {
        // Clear user information from local storage
        localStorage.removeItem('user');
        console.log("Log out successfull")

        // Redirect to the login route after logout
        navigate('/');
    };





    return (
        <DashboardLayout>

<Mydashboard/>

            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Today Quotations
                                </MDTypography>
                            </MDBox>

                            <MDBox pt={3}>
                                <div style={{ flexDirection: 'row', display: 'flex', marginLeft: 20 }}>

                                    <text style={{ width: '25%', fontSize: 15, fontWeight: '600', color: '#787675', marginLeft: 20 }}>Compnay name</text>
                                    <text style={styles.tabheader}>NAME</text>
                                    <text style={styles.tabheader}>PHONE</text>
                                    <text style={styles.tabheader}>STATUS</text>
                                    <text style={styles.tabheader}>DATE</text>
                                    <text style={styles.tabheader}>VIEW</text>

                                </div>




                                {todayQuotation.map((quotation, index) => (
                                    <div key={index} style={{ flexDirection: 'row', display: 'flex', padding: 3, marginTop: 10, backgroundColor: '#00e600', marginLeft: 10, marginRight: 10, borderRadius: 10 }}>

                                        <div style={{ width: '25%', flexDirection: 'column', display: 'flex', marginLeft: 25 }}>

                                            <text style={{ fontSize: 13, fontWeight: '600', color: '#000', marginLeft: 20 }}>{quotation.businessname}</text>
                                            <text style={{ fontSize: 13, marginLeft: 20, color: '#000' }}>{quotation.email}</text>
                                        </div>

                                        <text style={styles.tabData}>{quotation.name}</text>
                                        <text style={styles.tabData}>{quotation.mobile}</text>
                                        {/* <text style={styles.tabData}>{quotation._id}</text> */}
                                        <text style={styles.tabData}>{quotation.status}</text>
                                        <text style={styles.tabData}>{` ${moment(quotation.createdAt).format('DD-MM-YYYY')}`}</text>
                                        <Link to={`/view?quotationId=${quotation._id}`}>
                                            <PreviewIcon style={{}} color="inherit" sx={{ fontSize: 50 }} />
                                        </Link>

                                    </div>
                                ))}




                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            {/* <Footer /> */}


        </DashboardLayout>
    );
}

export default Todaylist;

const styles = {
    tabheader: {
        fontSize: 15,
        fontWeight: '600',
        color: '#787675',
        width: '15%'
    },
    tabData: {
        fontSize: 13,
        fontWeight: '600',
        color: '#000',
        width: '15%'
    }
}