/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { Avatar } from "@mui/material";
import Receptionhome from "MyComponetns/Receptionhome";

 import Addvisitor from "MyComponetns/Receptionist/Addvisitor";

function Reception() {
    const { sales, tasks } = reportsLineChartData;
    const imageUrl = require("../../assets/images/apple-icon.png");
    return (
        <DashboardLayout>
            <Avatar alt="User Avatar" src={imageUrl} sx={{ width: 24, height: 24 }} />
            <div>Dhiraj Ram</div>
          <Addvisitor/>
        </DashboardLayout>
    );
}

export default Reception;
