/**

*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Reception from "layouts/dashboard/Reception";
import Login from "MyComponetns/Login";
import AddQuotation from "MyComponetns/AddQuotation";
import AddQuotationDesign from "MyComponetns/QuotationDesign";
import ViewPdf from "MyComponetns/ViewPdf";
import QuotationList from "MyComponetns/QuotationList";

import Pendinglist from "MyComponetns/Pendinglist";
import Completedlist from "MyComponetns/Completed";
import Todaylist from "MyComponetns/Todaylist";
import Viewquotation from "MyComponetns/Viewquotation";
import { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';// import Receptionhome from "MyComponetns/Receptionhome";
import QutotationNew from "MyComponetns/Backup/QutotationNew";
import MyTables from "MyComponetns/myTable/myindex";

//  const navigate = useNavigate();
// const history = useHistory();


const handleLogout = () => {
  // Clear user information from local storage
  localStorage.removeItem('user');
  console.log("Log out successfull")
  
  // Redirect to the login route after logout
  //  navigate('/login');
};




const routes = [
 
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Addquotation",
  //   key: "Addquotation",
  //   icon: <Icon fontSize="small">Quotation</Icon>,
  //   route: "/quotation",
  //   component: <AddQuotation />,
  // },
  {
    type: "collapse",
    name: "NewQuotation",
    key: "quotation",
    icon: <Icon fontSize="small">add_circle</Icon>,
    route: "/newquotation",
    component: <AddQuotationDesign />,
  },
  {
    // type: "collapse",
    type: "hide",
    name: "pdf",
    key: "pdf",
    icon: <Icon fontSize="small">star</Icon>,
    route: "/pdf",
    component: <ViewPdf />,
  },


  {
    type: "collapse",
    name: "allquotation",
    key: "allquotation",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/allquotation",
    component: <QuotationList/>,
  },
  
  {
    type: "collapse",
    name: "Pending Quotation",
    key: "pendingquotation",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/pending",
    component: <Pendinglist
     />,
  },
  {
    type: "collapse",
    name: "complete",
    key: "complete",
    icon: <Icon fontSize="small">check_circle</Icon>,
    route: "/complete",
    component: <Completedlist />,
  },
  {
    type: "collapse",
    name: "today",
    key: "today",
    icon: <Icon fontSize="small">star</Icon>,
    route: "/today",
    component: <Todaylist />,
  },
  {
    // type: "collapse",
    type: "hide",
    name: "viewquotation",
    key: "view",
    icon: <Icon fontSize="small">Today Quotation</Icon>,
    route: "/view",
    component: <Viewquotation />,
  },
 

  {
    // type: "collapse",
    type: "hide",
    name: "Sign Out",
    key: "Signout",
    icon: <Icon fontSize="small">power_out</Icon>,
     route: "/",
  
    component: <Login />,
  },
  {
    type: "hide",
   
    name: "New Qutotation",
    key: "Quoto",
    icon: <Icon fontSize="small">power out</Icon>,
     route: "/newdkquotation",
  
    component: <QutotationNew />,
  },

  // {
  //   type: "collapse",
  //   name: "Add Visitor",
  //   key: "reception",
  //   icon: <Icon fontSize="small">reception</Icon>,
  //   route: "/reception",
  //   component: <Reception />,
  // },


  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Mytale",
  //   key: "myTable",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/mytable",
  //   component: <MyTables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
 
  
];

export default routes;
