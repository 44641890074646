import React, { useEffect, useState } from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { viewservices } from 'MyComponetns/MyApi/Apilist';

function QuotationNew() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    businessname: '',
    address: '',
    gstno: '',
    services: [],
    subtotal: '',
    notes: '',
  });

  const navigate = useNavigate();
  const [mobileError, setMobileError] = useState('');
  const [rows, setRows] = useState([
    { id: 1, servicename: '', quantity: 0, rate: 0, discount: 0, gst: 0, total: 0, note: '' },
  ]);

  const handleMobileInputChange = (e) => {
    const inputValue = e.target.value;
    setFormData({ ...formData, mobile: inputValue });

    if (inputValue.length < 10) {
      setMobileError('At least 10 digits.');
    } else {
      setMobileError(''); // Clear the error message
    }
  };

  const handleRowChange = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [field]: value };
          setFormData((prevFormData) => {
            if (field === 'servicename' || field === 'quantity' || field === 'rate' || field === 'discount' || field === 'gst' || field === 'note') {
              const updatedServices = prevFormData.services.map((service, index) =>
                index === id - 1
                  ? {
                      ...service,
                      [field]: value,
                      total: calculateAmount(value, service.rate, service.discount, service.gst),
                    }
                  : service
              );
              return { ...prevFormData, services: updatedServices };
            } else {
              return { ...prevFormData, [field]: value };
            }
          });
          return updatedRow;
        } else {
          return row;
        }
      })
    );
  };
  
  const calculateAmount = (quantity, rate, discount, gst) => {
    const total = quantity * rate;
    const discountedAmount = total - (total * discount) / 100;
    const gstAmount = (discountedAmount * gst) / 100;
    return discountedAmount + gstAmount;
  };

  const calculateSubtotal = () => {
    const subtotal = formData.services.reduce((subtotal, service) => {
      const totalAmount = calculateAmount(service.quantity, service.rate, service.discount || 0, service.gst || 0);
      return subtotal + totalAmount;
    }, 0);

    formData.subtotal = subtotal;
    return subtotal;
  };

  const addNewRow = () => {
    const newRow = {
      id: rows.length + 1,
      servicename: '',
      quantity: 0,
      rate: 0,
      discount: 0,
      gst: 0,
      total: 0,
      note: '',
    };
    setRows([...rows, newRow]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3005/api/v1/admin/addquotation', formData);
      console.log('Quotation added successfully:', response.data);
      console.log('response id:', response.data.quotation._id);

      const quotationId = response.data.quotation._id;
      navigate(`/pdf?quotationId=${quotationId}`);
    } catch (error) {
      console.error('Failed to add Quotation:', error.message);
    }
  };

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [testlist, setTestlist] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const testResponse = await axios.get('http://localhost:3005/api/v1/admin/services/list');
        
        const testResponse = await axios.get(viewservices);
        setTestlist(testResponse.data);
        console.log(testlist);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);

  const searchTests = (text) => {
    if (text.trim() === '') {
      setSearchResults([]);
    } else {
      const filteredTests = testlist.filter((test) =>
        (test.category && test.category.toLowerCase().includes(text.toLowerCase())) ||
        (test.subcategory && test.subcategory.some((sub) => sub.subcategoryname.toLowerCase().includes(text.toLowerCase())))
      );
      setSearchResults(filteredTests);
    }
    setSearchText(text);
  };

 


  const addToServices = (sub) => {
    const serviceName = sub.subcategoryname;
  
    if (formData.services.find((service) => service.servicename === serviceName)) {
      alert('This item is already in your services.');
    } else {
      setFormData((prevFormData) => {
        const updatedServices = [
          ...prevFormData.services,
          {
            servicename: serviceName,
            quantity: 0,
            rate: 0,
            discount: 0,
            gst: 0,
            total: 0,
          },
        ];
  
        // Update the 'total' field in the services array based on the calculated amount
        updatedServices[updatedServices.length - 1] = {
          ...updatedServices[updatedServices.length - 1],
          total: calculateAmount(updatedServices[updatedServices.length - 1].quantity, updatedServices[updatedServices.length - 1].rate, updatedServices[updatedServices.length - 1].discount, updatedServices[updatedServices.length - 1].gst),
        };
  
        return { ...prevFormData, services: updatedServices };
      });
  
      setRows((prevRows) =>
        prevRows.map((row) => {
          if (row.id === rows.length) {
            // Update the last added row's servicename with the selected subcategoryname
            return { ...row, servicename: serviceName };
          } else {
            return row;
          }
        })
      );
  
      setSelectedService(serviceName);
      console.log("selectedService: " + serviceName);
    }
  };
  
  
  

  const renderItem = ({ item }) => (
    <div>
      {item.subcategory && item.subcategory.length > 0 && (
        <div>
          {item.subcategory.map((sub, index) => (
            <div key={index} style={{ borderBottom: '1px solid #ccc', padding: '2px', display: 'flex', justifyContent: 'space-around', width: '29vmax', marginLeft: 10 }}>
              <text style={{ fontSize: 12, fontWeight: '500', width: '40%' }}>{sub.subcategoryname}</text>
              <text style={{ fontSize: 10, fontWeight: '300', width: '40%' }}>{sub.detail}</text>
              <button onClick={() => addToServices(sub)} style={{ backgroundColor: '#306b06', padding: '10px', width: 40 }}> +</button>

            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <DashboardLayout>
      <Card style={{ width: "75vmax", minWidth: "300px" }}>
        <MDBox pt={1} pb={1} px={3}>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <MDInput
                  type="text"
                  label="Customer Name"
                  fullWidth
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
              <div>
                <MDInput
                  type="text"
                  label="Phone"
                  fullWidth
                  value={formData.mobile}
                  onChange={(e) => handleMobileInputChange(e)}
                />
                {mobileError && <div style={{ color: 'red', width: '100%' }}>{mobileError}</div>}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
              <div>
                <MDInput
                  type="text"
                  label="Business Name"
                  fullWidth
                  value={formData.businessname}
                  onChange={(e) => setFormData({ ...formData, businessname: e.target.value })}
                />
              </div>
              <div>
                <MDInput
                  type="text"
                  label="Address"
                  fullWidth
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                />
              </div>
              <div>
                <MDInput
                  type="text"
                  label="GSTno"
                  fullWidth
                  value={formData.gstno}
                  onChange={(e) => setFormData({ ...formData, gstno: e.target.value })}
                />
              </div>
            </div>
          </div>
        </MDBox>

        <div>Product</div>
        <div>{searchResults.map((item) => renderItem({ item }))}</div>

        <MDBox>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Items</TableCell>
                  <TableCell style={styles.tableCell}>Quantity</TableCell>
                  <TableCell style={styles.tableCell}>Rate</TableCell>
                  <TableCell style={styles.tableCell}>Discount (%)</TableCell>
                  <TableCell style={styles.tableCell}>GST (%)</TableCell>
                  <TableCell style={styles.tableCell}>Amount</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <TextField
                        type="text"
                        value={row.servicename}
                        onChange={(e) => searchTests(e.target.value)}
                        onBlur={() => setSearchResults([])}
                      />
                      <TextField
                        type="text"
                        label="Note"
                        value={row.note}
                        onChange={(e) => handleRowChange(row.id, 'note', e.target.value)}
                      />
                    </TableCell>
                    <TableCell style={styles.inputCell}>
                      <TextField
                        type="number"
                        value={row.quantity}
                        onChange={(e) => handleRowChange(row.id, 'quantity', parseFloat(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={row.rate}
                        onChange={(e) => handleRowChange(row.id, 'rate', parseFloat(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={row.discount}
                        onChange={(e) => handleRowChange(row.id, 'discount', parseFloat(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={row.gst}
                        onChange={(e) => handleRowChange(row.id, 'gst', parseFloat(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>{calculateAmount(row.quantity, row.rate, row.discount, row.gst)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <div style={{ textAlign: 'right', marginTop: 10, marginRight: 20, marginBottom: -20 }}>
              SubTotal Amount: {calculateSubtotal()}
            </div>

            <div style={{ display: 'flex', border: '1px solid green', width: '50%', height: '70px', marginLeft: 20 }}>
              <textarea
                style={{ width: '100%', height: '100%', borderWidth: 1, fontSize: 14, borderColor: 'green', resize: 'none' }}
                placeholder='Enter Notes'
                value={formData.notes}
                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              />
            </div>

            <MDButton
              style={styles.addbtn}
              type="button"
              variant="gradient"
              color="info"
              fullWidth
              onClick={addNewRow}
            >
              Add New Items
            </MDButton>
            <MDButton
              style={styles.addbtn}
              type="button"
              variant="gradient"
              color="info"
              onClick={handleSubmit}
            >
              Generate
            </MDButton>
          </TableContainer>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default QuotationNew;

const styles = {
  patientInput: {
    width: '100%',
    borderColor: 'green',
  },
  addbtn: {
    marginTop: '2px',
    width: '15vw',
    marginLeft: 10,
  },
  tableCell: {
    width: 'auto', // Set a common width for header cells and input cells
  },
};
