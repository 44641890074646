import React, { useEffect, useState } from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


import { useNavigate } from 'react-router-dom'

//chatgpt
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Input } from '@mui/material';


import axios from 'axios';
import { addnewquotation } from './MyApi/Apilist';

function AddQuotationDesign() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        businessname: '',
        address: '',
        gstno: '',

        services: [],
        subtotal: '',
        notes: '',
    });

    const navigate = useNavigate();

    const [mobileError, setMobileError] = useState('');

    const [rows, setRows] = useState([
        { id: 1, servicename: '', quantity: 0, rate: 0, discount: 0, gst: 0, total: 0 },
    ]);

    const handleMobileInputChange = (e) => {
        const inputValue = e.target.value;
    
        setFormData({ ...formData, mobile: inputValue });
    
        if (inputValue.length < 10) {
          setMobileError('atleast 10 digits.');
        } else {
          setMobileError(''); // Clear the error message
        }
      };


    // const handleRowChange = (id, field, value) => {
    //     setRows((prevRows) =>
    //         prevRows.map((row) => {
    //             if (row.id === id) {
    //                 const updatedRow = { ...row, [field]: value };
    //                 setFormData((prevFormData) => {
    //                     if (field === 'servicename' || field === 'quantity' || field === 'rate' || field === 'discount' || field === 'gst') {
    //                         const updatedServices = [...prevFormData.services];
    //                         updatedServices[id - 1] = { ...updatedServices[id - 1], [field]: value };
    //                         // Update the 'total' field in the services array based on the calculated amount
    //                         updatedServices[id - 1] = { ...updatedServices[id - 1], total: calculateAmount(row.quantity, row.rate, row.discount, row.gst) };
    //                         return { ...prevFormData, services: updatedServices };
    //                     } else {
    //                         return { ...prevFormData, [field]: value };
    //                     }
    //                 });
    //                 return updatedRow;
    //             } else {
    //                 return row;
    //             }
    //         })
    //     );
    // };




    const handleRowChange = (id, field, value) => {
        setRows((prevRows) =>
            prevRows.map((row) => {
                if (row.id === id) {
                    const updatedRow = { ...row, [field]: value };
                    setFormData((prevFormData) => {
                        if (field === 'servicename' || field === 'quantity' || field === 'rate' || field === 'discount' || field === 'gst' || field === 'note') {
                            const updatedServices = [...prevFormData.services];
                            updatedServices[id - 1] = { ...updatedServices[id - 1], [field]: value };
                            // Update the 'total' field in the services array based on the calculated amount
                            updatedServices[id - 1] = {
                                ...updatedServices[id - 1],
                                total: calculateAmount(updatedServices[id - 1].quantity, updatedServices[id - 1].rate, updatedServices[id - 1].discount, updatedServices[id - 1].gst)
                            };
                            return { ...prevFormData, services: updatedServices };
                        } else {
                            return { ...prevFormData, [field]: value };
                        }
                    });
                    return updatedRow;
                } else {
                    return row;
                }
            })
        );
    };






    const calculateAmount = (quantity, rate, discount, gst) => {
        const total = quantity * rate;
        const discountedAmount = total - (total * discount) / 100;
        const gstAmount = (discountedAmount * gst) / 100;
        return discountedAmount + gstAmount;
    };








    // const calculateSubtotal = () => {
    //     // Iterate over the services array and sum up the 'total' values after discount and GST
    //     return formData.services.reduce((subtotal, service) => {
    //         const totalAmount = calculateAmount(service.quantity, service.rate, service.discount || 0, service.gst || 0);
    //         return subtotal + totalAmount;
    //     }, 0);
    // };




    const calculateSubtotal = () => {
        // Iterate over the services array and sum up the 'total' values after discount and GST
        const subtotal = formData.services.reduce((subtotal, service) => {
            const totalAmount = calculateAmount(service.quantity, service.rate, service.discount || 0, service.gst || 0);
            return subtotal + totalAmount;
        }, 0);

        formData.subtotal = subtotal;
        return subtotal;
    };









    const addNewRow = () => {
        const newRow = {
            id: rows.length + 1,
            servicename: '',
            quantity: 0,
            rate: 0,
            discount: 0,
            gst: 0,
            total: 0,
            note: '',
        };
        setRows([...rows, newRow]);
    };








    // axios
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send request to add Quotation by logedin user to the server
            const response = await axios.post(addnewquotation,formData 
            // const response = await axios.post('http://localhost:3005/api/v1/admin/addquotation', formData
                //       {
                //       headers: {
                //         Authorization: `Bearer ${token}`, // Replace with the actual token
                //     },
                // }
            );

            // Handle the response as needed
            console.log('Quotation added successfully:', response.data);
            console.log('response id id :', response.data.quotation._id);

            const quotationId = response.data.quotation._id;

            // navigate(`/pdf?data=${JSON.stringify(formData)}`);
            // console.log(quotationId)
            navigate(`/pdf?quotationId=${quotationId}`);

            fetchData();
        } catch (error) {
            // Handle errors (e.g., display an error message)
            console.error('Failed to add Quotation:', error.message);
        }
    };




    return (
        <DashboardLayout>
            <Card style={{ width: "75vmax", minWidth: "300px" }}>
                <MDBox pt={1} pb={1} px={3}>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <MDInput
                                    type="text"
                                    label=" Customer Name"
                                    fullWidth
                                    //   style={styles.boxStyle1}
                                    // sx={styles.boxStyle1}
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                />
                            </div>

                            <div>
                                <MDInput
                                    type="email"
                                    label="Email"
                                    fullWidth
                                    value={formData.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                />
                            </div>
                            {/* <div>
                                <MDInput
                                    type="number"
                                    label="Phone no"
                                    fullWidth
                                    value={formData.mobile}
                                    onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                                />
                            </div> */}
                            <div>
                                <MDInput
                                    type="text"
                                    label="Phone"
                                    fullWidth
                                    value={formData.mobile}
                                    onChange={(e) => handleMobileInputChange(e)}
                                />

                                {mobileError && <div style={{ color: 'red',width:'100%' }}>{mobileError}</div>}

                                {/* Rest of your component */}
                            </div>



                        </div>


                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
                            <div>
                                <MDInput
                                    type="text"
                                    label="Business Name"
                                    fullWidth
                                    value={formData.businessname}
                                    onChange={(e) => setFormData({ ...formData, businessname: e.target.value })}
                                />

                            </div>

                            <div>
                                <MDInput
                                    type="text"
                                    label="Address"
                                    fullWidth
                                    value={formData.address}
                                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                />
                            </div>
                            <div>
                                <MDInput
                                    type="text"
                                    label="GSTno"
                                    fullWidth
                                    value={formData.gstno}
                                    onChange={(e) => setFormData({ ...formData, gstno: e.target.value })}
                                />
                            </div>




                        </div>



                    </div>
                </MDBox>

                <div>
                    Product
                </div>




                <MDBox>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell >Items</TableCell>
                                    <TableCell style={styles.tableCell}>Quantity</TableCell>
                                    <TableCell style={styles.tableCell}>Rate / Unit</TableCell>
                                    <TableCell style={styles.tableCell}>Discount (%)</TableCell>
                                    <TableCell style={styles.tableCell} >GST (%)</TableCell>
                                    <TableCell style={styles.tableCell}>Amount</TableCell>
                                </TableRow>

                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id}>

                                            <TableCell>

                                                <TextField
                                                    type="text"
                                                    value={row.servicename}
                                                    onChange={(e) => handleRowChange(row.id, 'servicename', e.target.value)}
                                                />
                                                <TextField
                                                    type="text"
                                                    label="Note"
                                                    value={row.note}
                                                    onChange={(e) => handleRowChange(row.id, 'note', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell style={styles.inputCell}>
                                                <TextField
                                                    //   fullWidth
                                                    type="number"
                                                    value={row.quantity}
                                                    onChange={(e) => handleRowChange(row.id, 'quantity', parseFloat(e.target.value))}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    value={row.rate}
                                                    onChange={(e) => handleRowChange(row.id, 'rate', parseFloat(e.target.value))}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    value={row.discount}
                                                    onChange={(e) => handleRowChange(row.id, 'discount', parseFloat(e.target.value))}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    value={row.gst}
                                                    onChange={(e) => handleRowChange(row.id, 'gst', parseFloat(e.target.value))}
                                                />
                                            </TableCell>
                                            <TableCell>{calculateAmount(row.quantity, row.rate, row.discount, row.gst)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </TableHead>

                        </Table>
                        <div style={{ textAlign: 'right', marginTop: 10, marginRight: 20, marginBottom: -20 }}>
                            SubTotal Amount: {calculateSubtotal()}
                        </div>



                        {/* Add Notes */}
                        {/* <div style={{ display:'flex',border: '1px solid green', width: '50%', marginLeft: 20 }}>
                            <Input
                                style={{ width: '100%', height: '100%', borderWidth: 1 }}
                                placeholder='Enter Notes'
                                label="Notes"
                                value={formData.notes}
                                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                            />
                        </div> */}

                        <div style={{ display: 'flex', border: '1px solid green', width: '50%', height: '70px', marginLeft: 20 }}>
                            <textarea
                                style={{ width: '100%', height: '100%', borderWidth: 1, fontSize: 14, borderColor: 'green', resize: 'none' }}
                                placeholder='Enter Notes'
                                value={formData.notes}
                                onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                            />
                        </div>



                        <MDButton
                            style={styles.addbtn}
                            type="button"
                            variant="gradient"
                            color="info"
                            fullWidth
                            onClick={addNewRow}
                        >
                            Add New Items
                        </MDButton>
                        <MDButton
                            style={styles.addbtn}
                            type="button" variant="gradient" color="info" onClick={handleSubmit}>
                            Generate
                        </MDButton>



                    </TableContainer>




                </MDBox>
            </Card>
        </DashboardLayout>
    );
}

export default AddQuotationDesign;

const styles = {
    patientInput: {
        width: '100%',
        borderColor: 'green'
    },
    addbtn: {
        marginTop: '2px',
        width: "15vw",
        marginLeft: 10

    },
    tableCell: {
        width: 'auto', // Set a common width for header cells and input cells
    },

}