
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import axios from 'axios';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";


import GitHubIcon from "@mui/icons-material/GitHub";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';// import Receptionhome from "MyComponetns/Receptionhome";
import { viewlist } from "MyComponetns/MyApi/Apilist";




function Dashboard() {

  const { sales, tasks } = reportsLineChartData;
  const [totalQuotation, setTotalQuotation] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [compltedQuotation, setCompltedQuotation] = useState(0);
  const [todayQuotation, setTodayQuotation] = useState(0);


   const navigate = useNavigate();



  useEffect(() => {
    // Fetch data from the server to get total patients and total test lists
    const fetchData = async () => {
      try {
        // const quotationResponse = await axios.get('http://localhost:3005/api/v1/admin/viewList');
        const quotationResponse = await axios.get(viewlist);
        // const testListsResponse = await axios.get('http://localhost:3003/api/v1//test/viewtest');
        // console.log(quotationResponse.data)
        setTotalQuotation(quotationResponse.data.length);

        const completedQuotations = quotationResponse.data.filter((quotation) => quotation.status === "success");
        setCompltedQuotation(completedQuotations.length);


        const pendingQuotations = quotationResponse.data.filter((quotation) => quotation.status === "pending");
        setTotalPending(pendingQuotations.length);

        //Todays Quotation
        const today = new Date().toISOString().split('T')[0];
        const todayQuotations = quotationResponse.data.filter(
          (quotation) => new Date(quotation.createdAt).toISOString().split('T')[0] === today
        );

        // Set total pending quotations
        setTodayQuotation(todayQuotations.length);
        console.log(todayQuotation)


      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);



  const handleLogout = () => {
    // Clear user information from local storage
    localStorage.removeItem('user');
    console.log("Log out successfull")
    
    // Redirect to the login route after logout
      navigate('/');
  };
  




  return (
    <DashboardLayout>
      {
        localStorage.getItem('user') ?
        <>
      
     <div style={{textAlign:'right',marginBottom:-20,marginTop:-10,padding:5}}>
     <span onClick={handleLogout} style={{ cursor: 'pointer' }}>
     <GitHubIcon color="inherit" sx={{ fontSize: 30 }}/>
      Logout
      </span>
     </div>
      {/* <DashboardNavbar /> */}
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
          <Link to="/allquotation">
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"

                title="Total Quotation"
                count={totalQuotation}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
            </Link>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
          <Link to="/pending">
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Pending Quotation"
                count={totalPending}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
            </Link>
          </Grid>




          <Grid item xs={12} md={6} lg={3}>
          <Link to="/complete">
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Complte Quotation"
                count={compltedQuotation}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
            </Link>
          </Grid>



          <Grid item xs={12} md={6} lg={3}>

            <Link to="/today">
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"

                  icon="person_add"
                 
                  title="Today"
                  count={todayQuotation}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Create New Quotation",
                  }}
                />
              </MDBox>
            </Link>
          </Grid>
        </Grid>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      {/* <Footer /> */}
    
      </>
:
  <>
<div>
  Login To Accee this resource 
</div>
  </> 
 }
    </DashboardLayout>
  );
}

export default Dashboard;
