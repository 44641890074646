
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Document, Page, text, div, StyleSheet, PDFdiver, Image, Font } from '@react-pdf/renderer';
import { Card } from '@mui/material';
import moment from 'moment';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate } from 'react-router-dom'

import logo from "assets/myimages/cb.png";
import { quotationdetail, updateUrl } from './MyApi/Apilist';

function divquotation() {
  const location = useLocation();
  const quotationId = new URLSearchParams(location.search).get('quotationId');
  console.log('Quotation My ID:', quotationId);

  const [formData, setFormData] = useState({
    status: 'success'
  });

  const [myQuotation, setMyQuotation] = useState();

  const navigate = useNavigate();



  useEffect(() => {
    // Fetch data from the server to get total patients and total test lists
    const fetchData = async () => {
      try {
        // const quotationResponse = await axios.get(`http://localhost:3005/api/v1/admin/myquotation/${quotationId}`);
        const quotationResponse = await axios.get(`${quotationdetail}${quotationId}`);
        // const quotationResponse = await axios.get(`http://localhost:3005/api/v1/admin/myquotation/659f72b895a2553f1d7892c2`);

        setMyQuotation(quotationResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    // Log the updated state when myQuotation changes
    // console.log(myQuotation);
  }, [myQuotation]);


  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      // Send request to add Quotation by logedin user to the server
      // const response = await axios.post({`http://localhost:3005/api/v1/admin/updatequotation/${quotationId} `},formData
        const response = await axios.put(`${updateUrl}${quotationId}`,formData);
      // const response = await axios.put(`http://localhost:3005/api/v1/admin/updatequotation/${quotationId}`, formData);
      console.log('Updated successfully:', response.data);
      alert("Updated to Success")

      setFormData({
        status: 'dk'
      });


    } catch (error) {
      // Handle errors (e.g., display an error message)
      console.error('Failed to Update Statsus:', error.message);
    }
  };

  const handlePdf = () => {
    // Clear user information from local storage
   
    console.log("Log out successfull")
    
    // Redirect to the login route after logout
      navigate('/pdf');
      navigate(`/pdf?quotationId=${quotationId}`);
  };
  



  return (

    <DashboardLayout>
      <>
        {myQuotation && myQuotation.name ? (

          <div>
            divPdf
            <p>Name: {myQuotation.name}</p>


            <Card>

              <text style={{ marginLeft: 10 }}>{`${myQuotation.businessname}`}</text>
              <text style={styles.companydetail}>{`${myQuotation.mobile}`}</text>
              <text style={styles.companydetail}>{`${myQuotation.email}`}</text>
              <text style={styles.companydetail} >{`${myQuotation.address}`}</text>
              <text style={styles.companydetail}>{` GSTno ${myQuotation.gstno}`} </text>



              <div style={styles.taleHeader}>
                <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '95%', display: 'flex', alignItems: 'center' }}>
                  <text style={{ width: '26%', marginLeft: 15 }} >Product Name</text>
                  <text style={styles.headetext1} >Quantity</text>
                  <text style={styles.headetext1} >Rate/Unit</text>
                  <text style={styles.headetext1}>Discount</text>
                  <text style={styles.headetext1}>GST</text>
                  <text style={styles.headetext1}>Amount</text>
                </div>


              </div>

              {/*Tale Content  */}
              {myQuotation.services.map((item, index) => (
                <div key={index} style={{ flexDirection: 'row', display: 'flex' }}>
                  {/* <div  style={{ flexDirection: 'row' }}> */}
                  {/* <text style={{ width: '35%', marginLeft: 10, fontSize: 12, padding: 10 }} >{`${item.servicename}\n ${item.note}`}</text> */}
                  <div style={{ width: '30%', marginLeft: 20, padding: 10, display: 'flex' }}>
                    <text style={{ marginLeft: 10, fontSize: 12, fontWeight: '600' }} >{item.servicename}</text>
                    <text style={{ marginLeft: 10, fontSize: 11, fontWeight: '400', border: 0.5, borderRadius: 3, padding: 3 }} >{item.note}</text>
                  </div>
                  <text style={styles.datatext}>{`${item.quantity}`}</text>
                  <text style={styles.datatext}>{item.rate}</text>
                  <text style={styles.datatext}>{item.discount}</text>
                  <text style={styles.datatext}>{item.gst}</text>
                  <text style={styles.datatext}>{item.total}</text>
                </div>
              ))}
              <text style={{ fontSize: '15', fontWeight: '600', textAlign: 'right', marginTop: 20, marginRight: 10 }}>
                {`SubTotal:  ${myQuotation.subtotal}`}
              </text>
              {/* Bank Detail And Bill */}

              <div style={{ marginTop: -20 }}>
                <text style={{ fontSize: 13 }}>Notes</text>
                <text style={styles.companydetail}>{myQuotation.notes}</text>

              </div>


              <MDBox mb={2}>
                <text>{`Current status: ${myQuotation.status}`}</text>
              </MDBox>
             
             <div style={{flexDirection:'row',justifyContent:'space-around', display:'flex'}}>
             <MDBox mb={2} style={{ width: '200px', alignSelf: 'right' }}>
                <MDButton type="button" variant="gradient" color="info" fullWidth onClick={handleUpdate}>
                  Update to success
                </MDButton>

              </MDBox>

              <MDBox mb={2} style={{ width: '200px', alignSelf: 'right' }}>
                <MDButton type="button" variant="gradient" color="info" fullWidth onClick={handlePdf}>
                  View PDF
                </MDButton>
              </MDBox>
             </div>



            </Card>
            {/* Add other fields as needed */}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </>
    </DashboardLayout>
  );
}

export default divquotation;
const styles = StyleSheet.create({



  //tale
  taleHeader: {
    backgroundColor: '#61CE70',
    height: 40, width: '97%',
    border: 2,
    alignSelf: 'center',
    marginLeft: 10,
    borderColor: '#ffe',
    borderRadius: 5,
    display: 'flex'

  },


  datatext: {
    width: '13%',
    fontSize: 12,
    fontWeight: '500',
    padding: 10,
    textAlign: 'center'
  },

  //Terms And Condition
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
  content: {
    fontSize: 10,
    lineHeight: 1.5,
    margin: 5,
  },

  //company detail on header page1
  companydetailhead: {
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'left',
    marginLeft: 10
  },

  companydetail: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'left',
    marginLeft: 10,
    padding: 2,

  }

});