
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { Card } from '@mui/material';
import moment from 'moment';

import logo from "assets/myimages/cb.png";
import { quotationdetail } from './MyApi/Apilist';




function Header() {
  return (
    <View style={styles.header}>
      <View style={{ flexDirection: 'column' }}>
        <Image style={{ marginHorizontal: '10%', width: '300', height: '70' }} src={logo} />
        <Text style={{ fontSize: 15, color: 'green', fontWeight: '700', marginLeft: 40 }}>CIN : 8765432334</Text>
      </View>
      {/* <Image src={logo} style={{width:500,height:120}} /> */}

      {/* Add other header elements as needed */}
    </View>
  );
}

function Footer() {
  return (
    <View style={styles.footer}>

      <Text>Webel IT Park, DH Block(Newtown), Action Area I, Newtown, Kolkata- 700 156
        {'\n'} Ph- +91 9330047883 | Email- info@code-brackets.co
        {'\n'} www.code-brackets.com
      </Text>
      <Text render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
    </View>
  );
}








function ViewPdf() {
  const location = useLocation();
  const quotationId = new URLSearchParams(location.search).get('quotationId');

  console.log('Quotation My ID:', quotationId);

  const [myQuotation, setMyQuotation] = useState();

  useEffect(() => {
    // Fetch data from the server to get total patients and total test lists
    const fetchData = async () => {
      try {
        // const quotationResponse = await axios.get(`http://localhost:3005/api/v1/admin/myquotation/${quotationId}`);
        const quotationResponse = await axios.get(`${quotationdetail}${quotationId}`);
        // const quotationResponse = await axios.get(`http://localhost:3005/api/v1/admin/myquotation/659e419f19fb796cb3b3d159`);

        setMyQuotation(quotationResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    // Log the updated state when myQuotation changes
    console.log(myQuotation);
  }, [myQuotation]);

  //Date Converting

  // const dateString = myQuotation.createdAt;
  //  console.log(myQuotation.createdAt)

  // const parsedDate = moment(dateString);
  // const formattedDate = parsedDate.format('DD-MM-YYYY');
  // console.log(formattedDate); // Output: January 9, 2024 6:33 AM




  return (

    <DashboardLayout>
      <>
        {myQuotation && myQuotation.name ? (

          <div>
            ViewPdf
            <p>Name: {myQuotation.name}</p>


            <Card>
              <PDFViewer alignSelf={'center'} height={600}>
                <Document>

                  <Page size="A4" style={styles.page}>

                    <View style={{ width: '100%' }}>
                      <View style={{ border: 1, borderColor: '#000', paddingBottom: 20 }}>

                        <Text style={{ fontSize: 18, fontWeight: '900', color: '#8585ad', borderBottom: 1, textAlign: 'center', marginLeft: 5, marginRight: 5, padding: 12 }}>QUOTATION</Text>

                        <Header />

                        <Text style={{textAlign:'right',fontSize:14,marginTop:-40,marginBottom:20,marginRight:10 }}>{`Invoice No: ${myQuotation.invoiceNumber}`}</Text>
                        <Text style={{textAlign:'right',fontSize:14,marginTop:-10,marginRight:10  }}>{`Date: ${moment(myQuotation.createdAt).format('DD-MM-YYYY')}`}</Text>
                

                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 25 }}>
                          {/* <Image src={logo} /> */}
                          <View style={{ display: 'flex', width: '45%' }}>
                            <Text style={styles.companydetailhead}> {`\n`}Code Brackets Software Solutions Pvt Ltd</Text>
                            <Text style={styles.companydetail}>Email- info@code-brackets.com</Text>
                            <Text style={styles.companydetail}>contact - +91 9330047883 </Text>
                            <Text style={styles.companydetail}>Webel IT Park, DH Block(Newtown), Action Area I,
                              Newtown, Kolkata- 700 156  </Text>
                            <Text style={styles.companydetail}>GST No -19AAJCC5021D1ZZ </Text>
                            {`\n`}

                          </View>



                          <View style={{ width: '50%' }}>
                            <Text style={styles.companydetailhead}>Invoice To</Text>
                            {/* <Text style={styles.companydetailhead}>{`Date:________________________ ${myQuotation.createdAt} `}</Text> */}
                            <Text style={styles.companydetail}>{`${myQuotation.businessname}`}</Text>
                            <Text style={styles.companydetail}>{`${myQuotation.mobile}`}</Text>
                            <Text style={styles.companydetail}>{`${myQuotation.email}`}</Text>
                            <Text style={styles.companydetail}>{`${myQuotation.address}`}</Text>
                            <Text style={styles.companydetail}>{` GSTno ${myQuotation.gstno}`} </Text>

                          </View>



                        </View>



                        {/* Table for Product */}
                        <View>
                          <View style={styles.taleHeader}>

                            <View style={{ flexDirection: 'row' }}>
                              <Text style={{ width: '35%', marginLeft: 10, fontSize: 14, padding: 10 }} >Product Name</Text>
                              <Text style={styles.headetext}>Quantity</Text>
                              <Text style={styles.headetext}>Rate/Unit</Text>
                              <Text style={styles.headetext}>Discount</Text>
                              <Text style={styles.headetext}>GST</Text>
                              <Text style={styles.headetext}>Amount</Text>
                            </View>

                          </View>

                          {/*Tale Content  */}
                          {myQuotation.services.map((item, index) => (
                            <View key={index} style={{ flexDirection: 'row' }}>
                              {/* <View  style={{ flexDirection: 'row' }}> */}
                              {/* <Text style={{ width: '35%', marginLeft: 10, fontSize: 12, padding: 10 }} >{`${item.servicename}\n ${item.note}`}</Text> */}
                             <View style={{width:'35%',marginLeft:10,padding: 10}}>
                              <Text style={{ marginLeft: 10, fontSize: 12, }} >{item.servicename}</Text>


                              {item & item.note ? (
                              <Text style={{  marginLeft: 10, fontSize:9,fontWeight:'100',border:0.5,borderRadius:3,padding:3}} >{item.note}</Text>
                              ) : (
                                <p> </p>
                              )}
                        
                              </View>
                              <Text style={styles.datatext}>{`${item.quantity}`}</Text>
                              <Text style={styles.datatext}>{item.rate}</Text>
                              <Text style={styles.datatext}>{item.discount}</Text>
                              <Text style={styles.datatext}>{item.gst}</Text>
                              <Text style={styles.datatext}>{item.total}</Text>
                            </View>
                          ))}
                          <Text style={{ fontSize: '15', fontWeight: '600', textAlign: 'right', marginTop: 20, marginRight: 10 }}>
                            {`SubTotal:  ${myQuotation.subtotal}`}
                          </Text>
                          {/* Bank Detail And Bill */}

                          <View style={{marginTop:-20}}>
                              <Text style={{fontSize:13}}>Notes</Text>
                              <Text style={styles.companydetail}>{myQuotation.notes}</Text>
                             
                            </View>



                          <View style={{ flexDirection: 'row', justifyContent: 'space-between',marginTop:20 }}>

                            <View>
                              <Text> Payment Deatils</Text>
                              <Text style={styles.companydetail}>A/c - CODE BRACKETS SOFTWARE SOLUTIONS PVT. LTD..</Text>
                              <Text style={styles.companydetail}> A/c No- 018805006235</Text>
                              <Text style={styles.companydetail}>Account type- Current</Text>
                              <Text style={styles.companydetail}>IFS Code- ICIC0000188</Text>
                            </View>


                            <View>

                            </View>


                          </View>

                        </View>


                      </View>

                      <Footer />


                    </View>
                  </Page>








                  {/* Second Page */}
                  <Page size="A4" style={styles.page}>
                    <View style={{ width: '100%' }}>
                      <Header />

                      <View>
                        <Text style={styles.title}>Terms and Conditions</Text>

                        {/* Quotations and Estimates */}
                        <Text style={styles.subtitle}>QUOTATIONS AND ESTIMATES:</Text>
                        <Text style={styles.content}>
                          - Design can be changed to a maximum limitation of 1 time
                          {'\n'}
                          - Additional features will be chargeable
                          {'\n'}
                          - Support (except emergency) will be provided Mon-Fri (Except public holidays) 9:30 IST – 18:00 IST
                        </Text>

                        {/* Completion of Work */}
                        <Text style={styles.subtitle}>COMPLETION OF WORK:</Text>
                        <Text style={styles.content}>
                          . All timeframes offered by CODE BRACKETS Software Solutions are estimates.
                          {'\n'}
                          . CODE BRACKETS Software Solutions will endeavor to complete all work within the estimated timeframes.
                          {'\n'}
                          . CODE BRACKETS Software Solutions will not be liable for any penalties, monies, or hardships otherwise incurred by the client if the application cannot be delivered within the estimated timeframe.
                          {'\n'}
                          . CODE BRACKETS Software Solutions will not release the quoted application unless all payments have been met under the obligations of the quotation or work agreement.
                          {'\n'}
                          . If CODE BRACKETS Software Solutions does not have control over the residence where the finished work will reside then full payment must be made prior to said work being released by CODE BRACKETS Software Solutions.
                          {'\n'}
                          . The quoted application remains the property of CODE BRACKETS Software Solutions until all obligations have been met for the release of said application to the client.
                          {'\n'}
                          . If CODE BRACKETS Software Solutions is working as a third party to another company, said company is responsible for meeting the obligations for the release of the quoted application to their client.
                        </Text>

                        {/* Payment Method */}
                        <Text style={styles.subtitle}>Payment Method:</Text>
                        <Text style={styles.content}>
                          - Online
                          {'\n'}
                          - 50% advance, 50% after completion for the website.
                          {'\n'}
                          - For SEO and SMM, full payment at the beginning of every month.
                        </Text>

                        {/* Additional Notes */}
                        <Text style={{ fontSize: 16, color: '#393685', textAlign: 'center' }}>
                          HAPPY SERVING AND HAPPY HELPING..
                        </Text>

                        {/* Signatures */}
                        <Text style={styles.subtitle}>Customer Signatures:</Text>
                        {/* Add space for customer signatures */}

                        <Text style={styles.subtitle}>Authority Signatures:</Text>
                        {/* Add space for authority signatures */}
                      </View>




                      <Footer />

                    </View>
                  </Page>









                  {/* Third Page */}
                  <Page size="A4" style={styles.page}>
                    <View style={{ width: '100%' }}>
                      <Header />
                      <View>
                        <Text style={{ fontSize: 14 }}>Company Bank Details –</Text>
                        <Text style={{ fontSize: 10 }}>
                          A/c - CODE BRACKETS SOFTWARE SOLUTIONS PVT. LTD..
                          {'\n\n'}
                          A/c No- 018805006235
                          {'\n\n'}
                          Account type- Current
                          {'\n\n'}
                          IFS Code- ICIC0000188
                        </Text>
                      </View>



                      <Footer />
                    </View>
                  </Page>
                </Document>
              </PDFViewer>

            </Card>
            {/* Add other fields as needed */}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </>
    </DashboardLayout>
  );
}

export default ViewPdf;
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 20,
    paddingBottom: -10,
    // paddingTop: 30,
    // paddingBottom: 30,
    // paddingLeft: 20,
    // paddingRight: 20,
  },
  text: {
    fontSize: 12,
    color: '#333333',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  footer: {
    alignSelf: 'center',
    width: '110%',
    position: 'absolute',
    bottom: 10,
    padding: 20,
    // left: 40,
    // right: 40,
    border: 1,
    borderColor: "green",
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    backgroundColor: '#d1d1e0',
    textAlign: 'center',
    fontSize: 12,
    color: '#777777',
  },
  logoImage: {
    width: 500,
    height: 120,
  },


  //tale
  taleHeader: {
    backgroundColor: '#61CE70',
    height: 40, width: '100%',
    border: 2,
    borderColor: '#ffe',
    borderRadius: 5,

  },

  headetext: {
    width: '13%',
    fontSize: 12,
    fontWeight: '600',
    padding: 10,
    textAlign: 'center'
  },
  datatext: {
    width: '13%',
    fontSize: 12,
    fontWeight: '500',
    padding: 10,
    textAlign: 'center'
  },

  //Terms And Condition
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
  content: {
    fontSize: 10,
    lineHeight: 1.5,
    margin: 5,
  },

  //company detail on header page1
  companydetailhead: {
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'left',
    marginLeft: 10
  },

  companydetail: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'left',
    marginLeft: 10,
    padding: 2,

  }

});